<template>
  <v-container fluid>
    <v-card>
      <v-toolbar flat color="white">
        <v-text-field filled flat single-line hide-details dense clearable rounded v-model="busca" @click:clear="limparBusca" @keyup.enter="filtrar($event)" max-width="300px" prepend-inner-icon="search" :label="constantes.FILTRAR_CLIENTES"></v-text-field>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="novo" data-cy="novo">
          {{constantes.BOTAO_NOVO_CLIENTE}}
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-data-table :options.sync="options" :server-items-length="total" :footer-props="footerOptions" :headers="headers" :items="participantes" :loading="carregando">
        <template v-slot:item="props">
          <tr>
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" v-if="props.item.tipo === constantes.FORNECEDOR">mdi-account-tie</v-icon>
                  <v-icon v-on="on" v-else-if="props.item.tipo === constantes.CLIENTE">mdi-account</v-icon>
                  <v-icon v-on="on" v-else-if="props.item.tipo === constantes.CLIENTE_FORNECEDOR">mdi-account-switch</v-icon>
                </template>
                <span v-if="props.item.tipo === constantes.FORNECEDOR">Fornecedor</span>
                <span v-else-if="props.item.tipo === constantes.CLIENTE">Cliente</span>
                <span v-else-if="props.item.tipo === constantes.CLIENTE_FORNECEDOR">Cliente e Fornecedor</span>
              </v-tooltip>
            </td>
            <td class="text-no-wrap">{{ props.item.cnpj_cpf | formatarCnpj }}</td>
            <td>{{ props.item.inscricao !== '' ? props.item.inscricao : props.item.rg }}</td>
            <td class="text-truncate">{{ props.item.razao_nome }}</td>
            <td>{{ props.item.cidade }}</td>
            <td >{{ props.item.estado }}</td>
            <td class="text-center">

              <v-menu top right transition="slide-x-transition" @click.native.stop="">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" data-cy="menu-acoes">more_vert</v-icon>
                </template>
                <v-list dense>
                  <v-list-item :key="props.item._id" @click="editar(props.item)" data-cy="editar">
                    <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
                    <v-list-item-title>Editar</v-list-item-title>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item :key="props.item._id + 15" @click="remover(props.item)" data-cy="remover">
                    <v-list-item-icon><v-icon>delete</v-icon></v-list-item-icon>
                    <v-list-item-title>Remover</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>

  import {mapState, mapGetters, mapActions} from 'vuex';
  import constantes from '@/util/constantes';

  export default {
    data () {
      return {
        headers: [
          { text: 'Tipo', value: 'tipo', sortable: false },
          { text: 'CNPJ / CPF', value: 'cnpj_cpf', sortable: false },
          { text: 'IE / RG', value: 'inscricao', sortable: false },
          { text: 'Razão Social / Nome', value: 'razao_nome', sortable: false },
          { text: 'Cidade', value: 'cidade', sortable: false },
          { text: 'UF', value: 'estado', sortable: false },
          { text: 'Ações', value: 'name', align:'center',  sortable: false }
        ],
        constantes: constantes,
        busca: '',
        options: {
          itemsPerPage: 10,
          page: 1
        },
        footerOptions: {
          'items-per-page-options': constantes.ITEMS_POR_PAGINA 
        }
      }
    },

    watch: {
      options: {
        handler () {
          this.carregar();
        },
        deep: true,
      },
    },

    computed: {
      ...mapState('participante', {
        participantes: 'participantes',
        carregando: 'carregando',
        total: 'totalParticipantes'
      })
    },

    methods: {

      editar(participante){
        this.$store.commit('participante/editar', participante);
        this.$router.push({ name: 'formularioParticipanteEditar' });
      },

      novo(){
        this.$store.commit('participante/novo');
        this.$router.push({ name: 'formularioParticipanteNovo' });
      },

      carregar(){
        this.$store.dispatch('participante/carregar', this.options);
      },

      limparBusca(){
        this.carregar();
      },

      filtrar(event){

        let newOptions = {
          ...this.options,
          query: this.busca
        }

        this.$store.dispatch('participante/carregar', newOptions);
        setTimeout(() => {
          event.target.select();
        }, 300)
      },

      remover(participante){

        this.$root.$confirmacao.open('Remover', 'Tem certeza que deseja remover?')
        .then((confirmado) => {
          if(confirmado){
            this.$store.dispatch('participante/remover', participante);
          }
        });
      }
    }
  }
</script>